define(['zepto', 'lodash', 'warmupUtils', 'experiment'], function ($, _, warmupUtils, experiment) {
    'use strict';

    const INTERSECTION = {
        TIMEOUT: 19000, // MUST be shorter than ALIVE_TIMEOUT (20000) in tpaCompBaseMixin.js
        OPTIONS: {
            rootMargin: '50% 0px'
        }
    };

    const {tpaWarmup, loggingUtils: {performance}} = warmupUtils;

    function reportIframeStartLoading($iframe, siteData, lazyLoad, compId = $iframe.attr('name')) {
        if (tpaWarmup.isTPAComp(siteData, compId)) {
            tpaWarmup.reportWarmupPerformanceBiEvent(tpaWarmup.events.APP_IFRAME_START_LOADING, siteData, compId, {
                lazyLoad
            });
        }
    }

    function getRealQueryString(queryString, instancePath, clientSpecMap) {
        const realInstanceValue = _.get(clientSpecMap, instancePath);
        return _.replace(queryString, `\${${instancePath}}`, realInstanceValue);
    }

    function getRealConsentPolicy() {
        function isDefaultConsentPolicy(policytoTest) {
            return policytoTest.defaultPolicy === true && _.every(policytoTest.policy);
        }
        
        return experiment.isOpen('bv_consentPolicy') && window.consentPolicyManager && !isDefaultConsentPolicy(window.consentPolicyManager.getCurrentConsentPolicy()) ?
            `&consent-policy=${window.consentPolicyManager._getConsentPolicyHeader()['consent-policy']}` :
            '';
    }

    function getSrcAndReplaceTemplateValuesIfNeeded(siteData, src, compId) {
        if (src && src.indexOf('consent-policy') > 0) {
            src = src.replace(/&consent-policy=%7B%7BconsentPolicy%7D%7D/, getRealConsentPolicy());
        }
        const templatedValues = tpaWarmup.getTemplateValuesFromUrl(src);
        if (siteData.isClientAfterSSR() && !_.isEmpty(templatedValues)) {
            const {0: baseUrlPath, 1: instancePath} = templatedValues;
            const clientSpecMap = siteData.getClientSpecMap();
            const queryString = src.split('?')[1];
            const realQueryString = getRealQueryString(queryString, instancePath, clientSpecMap);
            tpaWarmup.reportWarmupPerformanceBiEvent(tpaWarmup.events.FAILED_TO_LOAD_IFRAME_FROM_SSR_CACHE, siteData, compId);
            return `${_.get(clientSpecMap, baseUrlPath)}?${realQueryString}`;
        }

        return src;
    }

    function prefetch(src) {
        const {document} = window;
        if (document) {
            const link = document.createElement('link');
            link.setAttribute('rel', 'prefetch');
            link.setAttribute('href', src);
            document.head.appendChild(link);
        }
    }

    const reCookie = /cookie|GDPR|CCPA/i;
    function isCookieMessageFrame(target) {
        return reCookie.test(target.getAttribute('aria-label')) || reCookie.test(target.getAttribute('title'));
    }

    return {
        setIframesSrc(siteData) {
            function setSrcFn($iframe, {dataSrc, lazyLoad}) {
                $iframe.attr('src', dataSrc);
                reportIframeStartLoading($iframe, siteData, lazyLoad);
            }

            const dataSrcFrames = $('#SITE_CONTAINER iframe[data-src]');
            let {length} = dataSrcFrames;
            if (length > 0) {
                const observed = ([iframe, props]) => {
                    setSrcFn($(iframe), props);
                    observer.unobserve(iframe);
                    if (--length === 0) {
                        observer.disconnect();
                        clearTimeout(timer);
                    }
                    propMap.delete(iframe);
                };

                const hasIntersectionObserver = typeof window !== 'undefined' && window.IntersectionObserver;
                const [propMap, observer] = hasIntersectionObserver && siteData.isViewerMode() && experiment.isOpen('bv_lazyTPAs') ? [
                    new Map(),
                    new window.IntersectionObserver(entries => {
                        const [inside, outside] = _.partition(entries,
                            ({isIntersecting, target}) => isIntersecting || target.offsetWidth < 2 || target.offsetHeight < 2 || isCookieMessageFrame(target));
                        _(inside)
                            .map(({target}) => [target, propMap.get(target)])
                            .forEach(observed);
                        _.forEach(outside, ({target}) => {
                            propMap.get(target).lazyLoad = true;
                        });
                    }, INTERSECTION.OPTIONS)
                ] : [];

                const timer = observer && setTimeout(() => {
                    _.forEach([...propMap.entries()], observed);
                }, INTERSECTION.TIMEOUT);

                dataSrcFrames.each(function () {
                    const $this = $(this);
                    const dataSrc = getSrcAndReplaceTemplateValuesIfNeeded(siteData, $this.data('src'), $this.attr('name'));
                    if (dataSrc) {
                        if (dataSrc !== $this.attr('src')) {
                            const props = {
                                dataSrc,
                                lazyLoad: false
                            };
                            if (observer) {
                                propMap.set(this, props);
                                observer.observe(this);
                                prefetch(dataSrc);
                            } else {
                                _.defer(setSrcFn, $this, props);
                            }
                        }
                        $this.removeAttr('data-src');
                    }
                });
            }
        },
        reportPresetIframes(siteData) {
            $('#SITE_CONTAINER iframe[src]').each(function () {
                const $this = $(this);
                const src = $this.attr('src');
                if (src) {
                    const name = $this.attr('name');
                    const dataSrc = getSrcAndReplaceTemplateValuesIfNeeded(siteData, $this.data('src'), name);
                    if (!dataSrc) {
                        performance.start(`Section ${name}`, {category: 'sections'});
                        reportIframeStartLoading($this, siteData, false, name);
                    }
                }
            });
        }
    };
});
